import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Typography } from '@mui/material';

import type { ReportEmployer } from '../../../../../../../../types';

import { TranslationKeys } from '../../../../../../../../translations';

import { ActionLink, ListRow, ListRowNoData, ListRowSkeleton } from '../../../../../../../../components';

import type { UserAccessesListOnChangeSelected, UserAccessesListSelectedData } from '../../UserAccessesList.types';

import { UserAccessesListHeader } from '../UserAccessesListHeader';
import { UserAccessesListContainer } from '../UserAccessesListContainer';
import { MuiCheckboxStyled } from '../../../../../../../../components/Checkbox/Checkbox.styles';
import { EditableFieldFactory } from '../../../../../../../../components/FormikField/Factory/EditableFieldFactory';

type UserAccessesListEmployersProps = {
  isLoading?: boolean;
  isLoggedInUser?: boolean;
  onChangeSelected: UserAccessesListOnChangeSelected;
  selectedData: UserAccessesListSelectedData | undefined;
  employers: Array<ReportEmployer> | undefined;
  selectedEventFlows: Array<string> | undefined;
  openConfirmationDialog: (value: boolean) => void;
};

export const UserAccessesListEmployers = ({
  employers,
  selectedData,
  onChangeSelected,
  selectedEventFlows,
  openConfirmationDialog,
  isLoggedInUser = false,
  isLoading = false,
}: UserAccessesListEmployersProps) => {
  const [searchQuery, setSearchQuery] = React.useState('');

  const listData = React.useMemo(() => {
    if (!searchQuery) {
      return employers;
    }

    const searchWords = searchQuery.toLowerCase().split(' ');
    return employers?.filter(({ name }) => searchWords.every(word => name.toLowerCase().includes(word)));
  }, [employers, searchQuery]);

  const selected = Object.keys(selectedData?.employers || {});
  const disabledIfLoadingOrIsLoggedInUser = isLoggedInUser || isLoading;
  const hasData = listData && listData?.length > 0;
  const showData = !isLoading && hasData;
  const showNoData = !isLoading && !showData;

  const onChangeSearchInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  const onSelectAll = React.useCallback(() => {
    const newSelectedData = JSON.parse(JSON.stringify(selectedData));

    if (employers && !selectedData?.areAllEmployersSelected) {
      newSelectedData.areAllEmployersSelected = true;

      newSelectedData.employers = employers.reduce<Record<string, string>>((acc, employer) => {
        acc[employer.employerId] = '*';
        return acc;
      }, {});
    } else {
      newSelectedData.areAllEmployersSelected = false;
      newSelectedData.employers = {};
    }

    onChangeSelected(newSelectedData);
  }, [employers, onChangeSelected, selectedData]);

  const onSelectHandler = React.useCallback(
    (employer: ReportEmployer) => () => {
      const newSelectedData = JSON.parse(JSON.stringify(selectedData));
      newSelectedData.areAllEmployersSelected = false;

      const isSelected = Object.keys(newSelectedData?.employers || {}).some(
        employerId => employerId === employer.employerId,
      );

      if (isSelected) {
        delete newSelectedData.employers[employer.employerId];
        onChangeSelected({
          ...newSelectedData,
          employers: {
            ...(newSelectedData?.employers || {}),
          },
        });
      }

      if (!isSelected) {
        onChangeSelected({
          ...newSelectedData,
          employers: {
            ...(newSelectedData?.employers || {}),
            [employer.employerId]: '*',
          },
        });
      }
    },
    [onChangeSelected, selectedData],
  );

  const onSelectEventFlow = React.useCallback(
    (employer: ReportEmployer) => () => {
      const selectedEventFlows = selectedData?.eventFlows || [];
      const autoAssignEmployers = selectedData?.autoAssignEmployers || false;
      const employerSelected = selectedEventFlows.includes(employer.employerId);

      // Use a functional approach to avoid direct mutation
      const newSelectedEventFlows = employerSelected
        ? selectedEventFlows.filter(id => id !== employer.employerId)
        : [...selectedEventFlows, employer.employerId];
      if (selectedData) {
        openConfirmationDialog(employer.eventFlowDefaultUser !== null);
        onChangeSelected({
          ...selectedData,
          eventFlows: newSelectedEventFlows,
        });
      } else {
        onChangeSelected({
          autoAssignEmployers: autoAssignEmployers,
          areAllEmployersSelected: false,
          areAllSubOwnersSelected: false,
          eventFlows: newSelectedEventFlows,
        });
      }
    },
    [onChangeSelected, openConfirmationDialog, selectedData],
  );

  return (
    <Box sx={theme => ({ marginTop: theme.spacing(3) })}>
      <UserAccessesListHeader
        headerTextId={TranslationKeys.users_accessesList_employers}
        onChange={onChangeSearchInput}
        isLoading={isLoading}
        disabled={disabledIfLoadingOrIsLoggedInUser}
        onSelectAll={onSelectAll}
        areAllSelected={selectedData?.areAllEmployersSelected}
        noData={listData?.length === 0}
        count={selected.length}
      />

      <EditableFieldFactory
        editing={true}
        field={{
          type: 'checkbox',
          name: 'autoAssignEmployers',
          label: useIntl().formatMessage({ id: TranslationKeys.users_accessesList_autoAssignEmployer }),
        }}
        value={selectedData?.autoAssignEmployers}
        onChange={(event: any) => {
          if (selectedData) {
            onChangeSelected({
              ...selectedData,
              autoAssignEmployers: event.value,
            });
          }
        }}
      />

      <UserAccessesListContainer>
        {isLoading && <ListRowSkeleton />}

        {showData &&
          listData?.map(employer => {
            const isSelected = selected.some(employerId => employerId === employer.employerId);
            const isEventFlowSelected = selectedEventFlows?.some(eventFlowId => eventFlowId === employer.employerId);

            return (
              <ListRow
                key={employer.employerId}
                selected={isSelected}
                onSelect={onSelectHandler(employer)}
                disabled={disabledIfLoadingOrIsLoggedInUser}
              >
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={2}>
                  <Typography>
                    <ActionLink to={`/employers/${employer.employerId}`}>{employer.name}</ActionLink>
                  </Typography>
                  <Typography variant={'caption'} textTransform={'initial'}>
                    <FormattedMessage
                      id={TranslationKeys.users_accessesList_subEmployers}
                      values={{ count: employer.subEmployers?.length }}
                    />
                  </Typography>
                </Box>
                <Typography sx={{ marginLeft: 'auto', padding: '0 10px 0 0' }}>
                  <MuiCheckboxStyled
                    color={'secondary'}
                    onClick={onSelectEventFlow(employer)}
                    checked={isEventFlowSelected && isSelected}
                    disabled={!isSelected || disabledIfLoadingOrIsLoggedInUser}
                  />
                  <Typography variant={'caption'} textTransform={'initial'}>
                    <FormattedMessage id={TranslationKeys.users_accessesList_defaultEventFlowUser} />
                  </Typography>
                </Typography>
              </ListRow>
            );
          })}
        {showNoData && <ListRowNoData searchQuery={searchQuery} />}
      </UserAccessesListContainer>
    </Box>
  );
};
