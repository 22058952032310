import React from 'react';
import { useIntl } from 'react-intl';
import type { FormikConfig, FormikHelpers } from 'formik';
import { Formik, setIn } from 'formik';
import type { FormikProps } from 'formik/dist/types';

import { Box, Grid } from '@mui/material';

import { isEmpty } from 'app/utils';

import { TranslationKeys } from 'app/translations';
import type {
  ApiField,
  Connection,
  ConnectionPackageSettings,
  Credential,
  CredentialSettings,
  Field,
  Product,
} from 'app/types';

import { ConnectionSourcePackageTypeEnum } from 'app/types';

import { useSnakeBar } from 'app/hooks';

import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from 'app/store';

import { INSURANCE_BRYDGE, onboardingService } from 'app/Domain/Connections/Services';

import { mapSettingsField, SettingsFieldGroup } from 'app/components/FormikField/Factory/SettingsFieldFactory';
import { IntlMessage } from 'app/components/Intl';
import CollapsableSection from 'app/components/CollapsableSection';

import { SettingsStepAnnualWageFactorWarningDialog, SettingsStepFieldsRender } from './components';

import { GridStyled, TypographyStyled } from './SettingsStep.styles';
import ErrorPanel from '../../../../../../components/ErrorPanel';

type SettingsStepProps = {
  packageSource: ConnectionSourcePackageTypeEnum;
  productId: Product['productId'];
  onSubmit: FormikConfig<any>['onSubmit'];
  sourceCredentials: Credential;
  stepState: {
    form: Record<string, any>;
    state: Record<string, any>;
  };
  targetPackageSettings: Array<{
    fields: ApiField[];
    packageType: string;
  }>;
  connection?: Connection;
  connectionLists?: any[];
  loading?: boolean;
};

const INSURANCE_BRYDGE_PACKAGE_TYPE_NAME = 'insurance-brydge';
const ANNUAL_WAGE_FACTOR_FIELD_NAME = 'annualWageFactor';
const PARTNER_SYSTEM_FIELD_NAME = 'partnerSystem';
const PGI_SOR_FORCE_UPDATE_FLAG_NAME = 'pgiSorForceUpdate';
const SYSTEM_PP_SOR_FORCE_UPDATE_FLAG_NAME = 'systemPPSorForceUpdate';

const SettingsStep = React.forwardRef(
  (
    {
      targetPackageSettings,
      sourceCredentials,
      productId,
      stepState,
      onSubmit,
      connection,
      packageSource,
      loading = false,
    }: SettingsStepProps,
    ref,
  ) => {
    const intl = useIntl();
    const dispatch = useStoreDispatch();

    const { showErrorSnakeBar } = useSnakeBar();

    const [warningDialogData, setWarningDialogData] = React.useState<{
      formikHelpers: FormikHelpers<any>;
      values: Record<string, any>;
      diff: {
        fieldName: string;
        currentValue: string;
        defaultValue: string;
      }[];
    } | null>(null);

    const formRef = React.createRef<FormikProps<any>>();
    const [initialLoading, setInitialLoading] = React.useState(true);

    const [allCredentialSettings, setAllCredentialSettings] = React.useState<CredentialSettings[]>(
      stepState.state.allCredentialSettings ?? [],
    );

    const [sourcePackageSettingsLoading, setSourcePackageSettingsLoading] = React.useState(
      stepState.state.sourcePackageSettingsLoading ?? true,
    );

    const [sourcePackageSettings, setSourcePackageSettings] = React.useState(
      stepState.state.sourcePackageSettings ?? null,
    );

    const { item: insuranceBrydgeSettingsDetails, loading: insuranceBrydgeSettingsDetailsLoading } = useStoreSelector(
      state => StoreSelectors.ConnectionsSelector.selectPackageSettingsDetails(state.ConnectionsReducer),
    );

    const [manualSettingsDetails, setManualSettingsDetails] = React.useState(null);
    const [isManualInput] = React.useState(packageSource === ConnectionSourcePackageTypeEnum.Manual);
    const settingsLoading = !!(initialLoading || insuranceBrydgeSettingsDetailsLoading || sourcePackageSettingsLoading);

    const initialSettings = React.useMemo(() => {
      return (connection?.packages || []).reduce<Record<string, ConnectionPackageSettings>>((map, p) => {
        map[p.packageType] = p.settings;
        return map;
      }, {});
    }, [connection?.packages]);

    React.useImperativeHandle(
      ref,
      () => ({
        submit() {
          if (settingsLoading || loading) {
            return;
          }
          formRef.current?.submitForm();
        },
        getState() {
          return {
            state: {
              initialLoading,
              allCredentialSettings,
              sourcePackageSettingsLoading,
              sourcePackageSettings,
            },
            form: formRef.current?.values,
          };
        },
      }),
      [
        allCredentialSettings,
        formRef,
        initialLoading,
        loading,
        settingsLoading,
        sourcePackageSettings,
        sourcePackageSettingsLoading,
      ],
    );

    React.useEffect(() => {
      if (initialLoading) {
        dispatch(
          StoreActions.ConnectionsActions.packageSettingsDetails.requestData({
            packageType: INSURANCE_BRYDGE,
            productId,
            connectionId: connection?.connectionId,
            sourcePackage: sourceCredentials?.packageType,
          }),
        );
        setInitialLoading(false);
      }
    }, [connection?.connectionId, dispatch, initialLoading, productId, sourceCredentials?.packageType]);

    const fetchManualSettingsDetails = React.useCallback(async (productId, connectionId) => {
      try {
        const response = await onboardingService.getPackageSettingsDetails({
          packageType: ConnectionSourcePackageTypeEnum.Manual,
          productId,
          connectionId,
          sourcePackage: ConnectionSourcePackageTypeEnum.Manual,
        });
        setManualSettingsDetails(response.data['hydra:member']);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }, []);

    React.useEffect(() => {
      if (isManualInput && productId !== null && !!connection?.connectionId) {
        fetchManualSettingsDetails(productId, connection?.connectionId);
      }
    }, [connection?.connectionId, fetchManualSettingsDetails, isManualInput, productId]);

    React.useEffect(() => {
      if (!sourceCredentials) {
        setSourcePackageSettingsLoading(false);

        if (isManualInput && manualSettingsDetails) {
          if (!Object.hasOwn(sourcePackageSettings || {}, 'credentialId')) {
            setSourcePackageSettings({
              credentialId: null,
              data: manualSettingsDetails,
            });
          }
          return;
        }

        setSourcePackageSettings(null);
        return;
      }

      if (sourceCredentials.credentialId === sourcePackageSettings?.credentialId) {
        return;
      }

      setSourcePackageSettingsLoading(true);

      onboardingService
        .getCredentialSettings({ credentialId: sourceCredentials.credentialId, productId })
        .then(result => {
          setSourcePackageSettings({
            credentialId: sourceCredentials.credentialId,
            data: result.data['hydra:member'],
          });
        })
        .catch(() => {
          showErrorSnakeBar({ method: 'getCredentialSettings' });
        })
        .finally(() => {
          setSourcePackageSettingsLoading(false);
        });
    }, [isManualInput, manualSettingsDetails, sourceCredentials, sourcePackageSettings, productId, showErrorSnakeBar]);

    React.useEffect(() => {
      setAllCredentialSettings(
        [sourcePackageSettings?.data, insuranceBrydgeSettingsDetails, targetPackageSettings]
          .filter(v => !isEmpty(v))
          .reduce((res, arr) => {
            return res.concat(arr);
          }, []),
      );
    }, [insuranceBrydgeSettingsDetails, sourcePackageSettings?.data, targetPackageSettings]);

    const getFieldsGroup = React.useCallback(
      group => {
        return allCredentialSettings.reduce<Field[]>((result, settings) => {
          const settingsFields = mapSettingsField(
            intl,
            settings,
            'onboarding.settings.',
            settingsLoading,
            stepState.form,
            initialSettings[settings.packageType] ?? null,
            group,
          );
          return result.concat(settingsFields);
        }, []);
      },
      [allCredentialSettings, initialSettings, intl, settingsLoading, stepState],
    );

    const connectionFields = React.useMemo(() => {
      return getFieldsGroup(SettingsFieldGroup.Connection);
    }, [getFieldsGroup]);

    const rulesFields = React.useMemo(() => {
      return getFieldsGroup(SettingsFieldGroup.Rules);
    }, [getFieldsGroup]);

    const advancedFields = React.useMemo(() => {
      return getFieldsGroup(SettingsFieldGroup.Advanced);
    }, [getFieldsGroup]);

    const formikInitialValues = React.useMemo(() => {
      let values = {};

      [connectionFields, rulesFields, advancedFields].forEach(group =>
        group.forEach(field => {
          values = setIn(values, field.name, field.value ?? field.defaultValue ?? null);
        }),
      );

      return { ...values, [PGI_SOR_FORCE_UPDATE_FLAG_NAME]: false, [SYSTEM_PP_SOR_FORCE_UPDATE_FLAG_NAME]: false };
    }, [connectionFields, rulesFields, advancedFields]);

    const formikOnSubmit = React.useCallback<FormikConfig<any>['onSubmit']>(
      (values, formikHelpers) => {
        const diff = [];
        const insuranceBrydgeCredentialsSettings = allCredentialSettings.find(
          credentialSettings => credentialSettings.packageType === INSURANCE_BRYDGE_PACKAGE_TYPE_NAME,
        );

        const defaultAnnualWageFactor = insuranceBrydgeCredentialsSettings?.fields?.find(
          field => field.fieldName === ANNUAL_WAGE_FACTOR_FIELD_NAME,
        );
        const defaultPartnerSystem = insuranceBrydgeCredentialsSettings?.fields?.find(
          field => field.fieldName === PARTNER_SYSTEM_FIELD_NAME,
        );

        const currentAnnualWageFactorValue = values.packages?.[INSURANCE_BRYDGE_PACKAGE_TYPE_NAME]?.annualWageFactor;
        const currentPartnerSystemValue = values.packages?.[INSURANCE_BRYDGE_PACKAGE_TYPE_NAME]?.partnerSystem;

        if (
          defaultAnnualWageFactor &&
          currentAnnualWageFactorValue !== undefined &&
          defaultAnnualWageFactor.fieldOptions.default !== currentAnnualWageFactorValue
        ) {
          diff.push({
            fieldName: ANNUAL_WAGE_FACTOR_FIELD_NAME,
            currentValue: currentAnnualWageFactorValue,
            defaultValue: defaultAnnualWageFactor.fieldOptions.default,
          });
        }

        if (
          defaultPartnerSystem &&
          currentPartnerSystemValue !== undefined &&
          defaultPartnerSystem.fieldOptions.default !== currentPartnerSystemValue
        ) {
          diff.push({
            fieldName: PARTNER_SYSTEM_FIELD_NAME,
            currentValue: currentPartnerSystemValue,
            defaultValue: defaultPartnerSystem.fieldOptions.default,
          });
        }

        if (diff.length > 0) {
          setWarningDialogData({
            formikHelpers,
            values,
            diff,
          });
        } else {
          onSubmit(values, formikHelpers);
        }
      },
      [allCredentialSettings, onSubmit],
    );

    const handleDiffsFromSOR = React.useCallback(
      (synchronize: boolean) => () => {
        if (warningDialogData) {
          const values = warningDialogData.values;

          if (warningDialogData.diff.some(diff => diff.fieldName === ANNUAL_WAGE_FACTOR_FIELD_NAME)) {
            values[PGI_SOR_FORCE_UPDATE_FLAG_NAME] = synchronize;
            warningDialogData.formikHelpers.setFieldValue(PGI_SOR_FORCE_UPDATE_FLAG_NAME, synchronize);
          }

          if (warningDialogData.diff.some(diff => diff.fieldName === PARTNER_SYSTEM_FIELD_NAME)) {
            values[SYSTEM_PP_SOR_FORCE_UPDATE_FLAG_NAME] = synchronize;
            warningDialogData.formikHelpers.setFieldValue(SYSTEM_PP_SOR_FORCE_UPDATE_FLAG_NAME, synchronize);
          }

          onSubmit(values, warningDialogData.formikHelpers);
        }

        setWarningDialogData(null);
      },
      [warningDialogData, onSubmit],
    );

    return (
      <>
        <Formik enableReinitialize initialValues={formikInitialValues} onSubmit={formikOnSubmit} innerRef={formRef}>
          {packageSource === ConnectionSourcePackageTypeEnum.OCR ? (
            <ErrorPanel header={'onboarding.settings.unavailableHeader'} body={'onboarding.settings.unavailableBody'} />
          ) : (
            <GridStyled container spacing={2}>
              <Grid item xs={12}>
                <TypographyStyled variant={'subtitle1'}>
                  <IntlMessage value={TranslationKeys.onboarding_settings_connection} />
                </TypographyStyled>
              </Grid>

              <SettingsStepFieldsRender isLoading={settingsLoading} fields={connectionFields} />

              <Grid item xs={12}>
                <TypographyStyled variant={'subtitle1'}>
                  <IntlMessage value={TranslationKeys.onboarding_settings_rules} />
                </TypographyStyled>
              </Grid>

              <SettingsStepFieldsRender isLoading={settingsLoading} fields={rulesFields} />

              <Grid item xs={12}>
                <CollapsableSection
                  header={TranslationKeys.onboarding_settings_advanced}
                  loading={settingsLoading || loading}
                  hidden={isEmpty(advancedFields)}
                >
                  {!isEmpty(advancedFields) && (
                    <>
                      <Box p={1} />
                      <Grid container spacing={2}>
                        <SettingsStepFieldsRender fields={advancedFields} />
                      </Grid>
                    </>
                  )}
                </CollapsableSection>
              </Grid>
            </GridStyled>
          )}
        </Formik>

        {!!warningDialogData && (
          <SettingsStepAnnualWageFactorWarningDialog
            show={!!warningDialogData}
            diff={warningDialogData.diff}
            onClickKeepBoth={handleDiffsFromSOR(false)}
            onClickSynchronize={handleDiffsFromSOR(true)}
          />
        )}
      </>
    );
  },
);

SettingsStep.displayName = 'SettingsStep';

export default SettingsStep;
