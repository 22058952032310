import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { FormikModal } from 'app/components';
import EditEmployerForm from '../EditEmployerForm';
import { TranslationKeys } from '../../../../translations';
import { employerService } from '../../Services/EmployerService';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';

const EditEmployerFormModal = ({ employer, owner, open, onSubmit, onHide }) => {
  const intl = useIntl();

  const debouncedValidateField = useMemo(() => {
    return debounce((field, value, employerId, resolve, reject) => {
      employerService
        .validateField(field, value, employerId)
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          if (error.response?.status === 422) {
            resolve(false);
          } else {
            reject(error);
          }
        });
    }, 500);
  }, []);

  const validateField = useCallback(
    (field, value, employerId) => {
      return new Promise((resolve, reject) => {
        debouncedValidateField(field, value, employerId, resolve, reject);
      });
    },
    [debouncedValidateField],
  );

  const validateInternalNumber = useCallback(
    async (value, employerId) => {
      if (!value) return true;
      return await validateField('internalNumber', value, employerId);
    },
    [validateField],
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      employerName: Yup.string().trim().min(2).max(255).required(),
      employerEmail: Yup.string().trim().min(2).max(255).email().nullable(),
      employerPhone: Yup.string().trim().min(2).max(50).nullable(),
      contactName: Yup.string().trim().min(2).max(255).nullable(),
      contactEmail: Yup.string().trim().min(2).max(255).email().nullable(),
      contactPhone: Yup.string().trim().min(2).max(50).nullable(),
      internalNumber: Yup.string()
        .trim()
        .strict()
        .max(255)
        .test(
          'internalNumber',
          intl.formatMessage({ id: TranslationKeys.validationMessage_internalNumber_alreadyExists }),
          async value => {
            if (!value) return true;
            return await validateInternalNumber(value, employer.employerId);
          },
        )
        .nullable(),
      chamberOfCommerce: Yup.string().trim().min(2).max(50).nullable(),
      sectorCode: Yup.string().trim().min(2).max(50).nullable(),
      payrollTaxNumber: Yup.string().trim().min(2).max(50).nullable(),
      isPartOfHolding: Yup.boolean(),
      parentId: Yup.string().when('isPartOfHolding', {
        is: true,
        then: schema => schema.trim().required(),
        otherwise: schema => schema.trim().nullable(),
      }),
      subOwnerId: Yup.string().trim().nullable(),
      zipCode: Yup.string().trim().min(1).max(255).required(),
      city: Yup.string().trim().min(1).max(255).required(),
      address: Yup.string().trim().min(1).max(255).required(),
    });
  }, [intl, validateInternalNumber, employer.employerId]);

  const initialValues = useMemo(() => {
    return {
      employerId: employer?.employerId,
      employerName: employer?.employerName,
      employerEmail: employer?.employerEmail,
      employerPhone: employer?.employerPhone,
      internalNumber: employer?.internalNumber,
      contactName: employer?.contactName,
      contactEmail: employer?.contactEmail,
      contactPhone: employer?.contactPhone,
      chamberOfCommerce: employer?.chamberOfCommerce,
      sectorCode: employer?.sectorCode,
      payrollTaxNumber: employer?.payrollTaxNumber,
      address: employer?.address,
      zipCode: employer?.zipCode,
      city: employer?.city,
      isPartOfHolding: !!employer?.parentId,
      status: employer.status,
      parentId: employer?.parentId,
      ownerId: employer?.ownerId,
      subOwnerId: owner.parent ? owner.ownerId : null,
      owner: owner['@id'],
      parent: employer?.parentId
        ? employer['@id'].substring(0, employer['@id'].lastIndexOf('/') + 1) + employer.parentId
        : null,
    };
  }, [employer, owner]);

  const submitEditEmployer = useCallback(
    (values, form) => {
      onSubmit && onSubmit(values, form);
    },
    [onSubmit],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitEditEmployer}>
      <FormikModal maxWidth="md" show={open} title={employer?.employerName} onHide={onHide}>
        <EditEmployerForm ownerData={owner} />
      </FormikModal>
    </Formik>
  );
};

export default EditEmployerFormModal;
