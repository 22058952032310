import React from 'react';
import { useParams } from 'react-router-dom';

import { StoreSelectors, useStoreSelector } from '../../../../../../store';

import { RegulationsPanelForm } from '../../../../../Regulations/Components/RegulationsPanelForm';
import { useInsurerGetInsurerQuery } from '../../../../../../hooks';
import { IsOwnerAdmin } from '../../../../../../components';

export const InsurerProductSummaryTab = () => {
  const isOwner = IsOwnerAdmin();
  const { insurerId, productId } = useParams();

  const { data: insurer, isLoading: isLoadingInsurer } = useInsurerGetInsurerQuery({
    variables: { insurerId },
    options: {
      enabled: !!insurerId,
    },
  });

  const products = React.useMemo(() => {
    return insurer?.products?.filter(product => product.productId === productId);
  }, [insurer?.products, productId]);

  const ownerId = useStoreSelector(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  return (
    <RegulationsPanelForm ownerId={ownerId} products={products} productsLoading={isLoadingInsurer} editable={isOwner} />
  );
};
