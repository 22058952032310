import React, { createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Formik, getIn, setIn, setNestedObjectValues } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  actions as CredentialsActions,
  selectors as credentialsSelectors,
} from 'app/Domain/Credentials/Ducks/Credentials.duck';
import { actions as OwnerActions, selectors as ownerSelectors } from 'app/Domain/Owners/Ducks/Owner.duck';
import { isEmpty, isNil, startCase, useDeepMemo } from 'app/utils';
import { Box, Fade, Grid } from '@mui/material';
import { InputFieldFactory } from 'app/components/FormikField';
import { selectors as appSelectors } from 'app/Domain/App/Ducks/App.duck';
import CollapsableSection from 'app/components/CollapsableSection';
import ActionButton from 'app/components/ActionButton';
import { mapSettingsField } from 'app/components/FormikField/Factory/SettingsFieldFactory';
import {
  CREDENTIAL_TYPE_OAUTH,
  INSURANCE_BRYDGE,
  NEW_CREDENTIAL_VALUE,
} from 'app/Domain/Connections/Services/OnboardingService';

import credentialService from 'app/Domain/Credentials/Services/CredentialService';
import { CREDENTIAL } from '../../../../../common/Authorization/entities';
import { EDIT } from '../../../../../common/Authorization/permissions';
import { HasAccessTo } from '../../../../../components';
import { ConnectionSourcePackageTypeEnum } from '../../../../../types';

const getPackageSources = selectedProduct => {
  const packages = [
    {
      element: 'API',
      value: ConnectionSourcePackageTypeEnum.API,
    },
    {
      element: 'packages.type.file-upload',
      value: ConnectionSourcePackageTypeEnum.FileUpload,
    },
  ];
  if (selectedProduct?.pensionScheme === 'regular') {
    packages.push({
      element: 'packages.type.manual-input',
      value: ConnectionSourcePackageTypeEnum.Manual,
    });
  }
  if (selectedProduct?.pensionScheme === 'policy-package') {
    packages.push({
      element: 'packages.type.ocr',
      value: ConnectionSourcePackageTypeEnum.OCR,
    });
  }
  return packages;
};

const getInitialPackageType = (targetPackageSettings, connection) => {
  if (isNil(connection)) {
    return null;
  }
  const targetPackageType = (targetPackageSettings || [])[0]?.packageType ?? null;

  const connectionPackageTypes = (connection?.packages || []).map(p => p.packageType);

  return (
    connectionPackageTypes.filter(
      packageType => packageType !== INSURANCE_BRYDGE && packageType !== targetPackageType,
    )[0] ?? null
  );
};

const getInitialPackageSource = (initialPackageType, connection) => {
  if (isNil(connection)) {
    return null;
  }

  if (
    isEmpty(initialPackageType) ||
    initialPackageType === ConnectionSourcePackageTypeEnum.Manual ||
    initialPackageType === ConnectionSourcePackageTypeEnum.OCR
  ) {
    return initialPackageType === ConnectionSourcePackageTypeEnum.Manual
      ? ConnectionSourcePackageTypeEnum.Manual
      : ConnectionSourcePackageTypeEnum.OCR;
  }

  if (initialPackageType === ConnectionSourcePackageTypeEnum.FileUpload) {
    return ConnectionSourcePackageTypeEnum.FileUpload;
  }

  return ConnectionSourcePackageTypeEnum.API;
};

const SourcePackageCredentialsStep = forwardRef(
  ({ employer, onSubmit, stepState, targetPackageSettings, connection, selectedProduct, loading = false }, ref) => {
    const formRef = createRef();
    const intl = useIntl();
    const dispatch = useDispatch();

    const existingConnection = !isNil(connection);
    const initialPackageType = getInitialPackageType(targetPackageSettings, connection);
    const initialPackageSource = getInitialPackageSource(initialPackageType, connection);
    const initialPackage = (connection?.packages || []).filter(p => p.packageType === initialPackageType)[0] ?? null;

    const [packageSource, setPackageSource] = useState(stepState.state.packageSource ?? initialPackageSource ?? null);
    const [packageId, setPackageId] = useState(stepState.state.packageId ?? null);
    const [selectedPackage, setSelectedPackage] = useState(stepState.state.selectedPackage ?? null);
    const [credentialId, setCredentialId] = useState(
      stepState.state.credentialId ?? initialPackage?.credentialId ?? null,
    );
    const [selectedCredential, setSelectedCredential] = useState(stepState.state.selectedCredential ?? null);
    const [packageRequired, setPackageRequired] = useState(stepState.state.packageRequired ?? false);
    const [creatingCredential, setCreatingCredential] = useState(false);

    const selectedPackageName = startCase(selectedPackage?.packageType?.replace('-', ' ') ?? '');

    const ownerId = useSelector(state => appSelectors.selectOwnerId(state.AppReducer));
    const isManual = packageSource === ConnectionSourcePackageTypeEnum.Manual;
    const isOCR = packageSource === ConnectionSourcePackageTypeEnum.OCR;
    const isFileUpload = packageSource === ConnectionSourcePackageTypeEnum.FileUpload;

    const hasAccessToEditCredentials = HasAccessTo(CREDENTIAL, EDIT);

    const { item: availablePackages, loading: packageTypesLoading } = useSelector(state =>
      credentialsSelectors.selectAvailablePackages(state.CredentialsReducer),
    );

    const { items: credentials, loading: credentialsLoading } = useSelector(state =>
      ownerSelectors.selectAvailablePackageCredentials(state.OwnerReducer),
    );

    const { item: credentialDetails, loading: credentialDetailsLoading } = useSelector(state =>
      credentialsSelectors.selectCredentialDetails(state.CredentialsReducer),
    );

    const creatingNewCredentials = credentialId === NEW_CREDENTIAL_VALUE;
    const showNewCredentialsFields =
      creatingNewCredentials && selectedCredential === null && !!packageId && !!selectedPackage;

    // when the data is reloaded, even though the content might be the same, the array reference is not and will
    // trigger unnecessary effects
    const availableSourcePackages = useDeepMemo(
      (availablePackages || []).filter(p => p.packageSource === packageSource),
    );

    const getCredentials = useCallback(
      (packageSelected, onSuccess = undefined) => {
        return dispatch(
          OwnerActions.availablePackageCredentials.requestData({
            packageType: packageSelected.packageType,
            ownerId: ownerId || employer.owner?.parentId || employer.owner?.ownerId,
            onSuccess: onSuccess,
          }),
        );
      },
      [dispatch, employer.owner?.ownerId, employer.owner?.parentId, ownerId],
    );

    const createCredential = useCallback(
      async (formik, onSuccess = undefined) => {
        const errors = await formik.validateForm();
        if (!isEmpty(errors)) {
          formik.setTouched(setNestedObjectValues(errors, true));
          return;
        }
        setCreatingCredential(true);
        try {
          const response = await credentialService.createCredential({
            ownerId: ownerId,
            package: {
              packageType: selectedPackage.packageType,
              credential: {
                ...Object.fromEntries(Object.entries(getIn(formik.values, `packages.${selectedPackage.packageType}`))),
                credentialType: selectedPackage.packageType,
              },
              credentialRequest:
                credentialDetails.credentialType === CREDENTIAL_TYPE_OAUTH
                  ? {
                      successUrl: `${window.location.origin}/credential/oauth/success/{credentialId}`,
                      failureUrl: `${window.location.origin}/credential/oauth/error/{credentialId}`,
                    }
                  : undefined,
            },
          });
          const newCredential = response.data;
          setSelectedCredential(newCredential);
          setCredentialId(newCredential.credentialId);
          const success = () => {
            onSuccess && onSuccess(newCredential);
          };
          getCredentials(selectedPackage, success);
          return newCredential;
        } finally {
          setCreatingCredential(false);
        }
      },
      [credentialDetails, getCredentials, ownerId, selectedPackage],
    );

    const changeCredentialId = useCallback(({ value: credentialId }) => {
      setCredentialId(credentialId);
    }, []);

    const changePackage = useCallback(({ value: packageId }) => {
      setPackageId(packageId);
      setSelectedCredential(null);
      setCredentialId(null);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        async submit() {
          const formik = formRef.current;
          const requiresMoreInformationToSubmit = !isManual && !isOCR;
          const didNotLoadInformationYet = packageTypesLoading || credentialsLoading || loading;

          if (requiresMoreInformationToSubmit && didNotLoadInformationYet) {
            return;
          }
          if (creatingNewCredentials && credentialDetailsLoading) {
            return;
          }
          if (showNewCredentialsFields) {
            const onSuccess = () => {
              formik.submitForm();
            };
            await createCredential(formik, onSuccess);
          } else {
            formik.submitForm();
          }
        },
        getState() {
          const formState = { ...formRef.current.values };
          if (selectedPackage) {
            formState.credentials = {
              [selectedPackage.packageType]: credentialId,
            };
          }

          return {
            state: {
              packageSource,
              packageId,
              selectedPackage,
              credentialId,
              selectedCredential,
              packageRequired,
            },
            form: formState,
          };
        },
        canGoBack() {
          return !creatingCredential;
        },
      }),
      [
        createCredential,
        creatingCredential,
        creatingNewCredentials,
        credentialDetailsLoading,
        credentialId,
        credentialsLoading,
        formRef,
        isManual,
        isOCR,
        loading,
        packageId,
        packageRequired,
        packageSource,
        packageTypesLoading,
        selectedCredential,
        selectedPackage,
        showNewCredentialsFields,
      ],
    );

    // Note: order matters here since the last "set state" prevails
    useEffect(() => {
      if (!isNil(packageSource)) {
        const packageRequired = !isManual && !isOCR;
        setPackageRequired(packageRequired);
      }
    }, [changePackage, dispatch, isManual, isOCR, packageSource]);

    useEffect(() => {
      dispatch(CredentialsActions.availablePackages.requestData({ packages: [INSURANCE_BRYDGE] }));
    }, [dispatch]);

    useEffect(() => {
      const requiresPackageLoading = !isManual && !isOCR;
      if (requiresPackageLoading && packageTypesLoading) {
        return;
      }

      if (packageId) {
        const packageSelected = (availableSourcePackages || []).find(p => p['@id'] === packageId) ?? null;
        setPackageId(packageId);
        setSelectedPackage(packageSelected);
      } else if (!isNil(initialPackageType) && packageSource === initialPackageSource) {
        const packageSelected = (availableSourcePackages || []).find(p => p.packageType === initialPackageType) ?? null;
        setSelectedPackage(packageSelected);
        setPackageId(isNil(packageSelected) ? null : packageSelected['@id']);
        setCredentialId(initialPackage?.credentialId);
      } else if (availableSourcePackages?.length === 1) {
        const packageSelected = availableSourcePackages[0];
        setSelectedPackage(packageSelected);
        setPackageId(isNil(packageSelected) ? null : packageSelected['@id']);
      }
    }, [
      availableSourcePackages,
      initialPackage?.credentialId,
      initialPackageSource,
      initialPackageType,
      isManual,
      isOCR,
      packageId,
      packageSource,
      packageTypesLoading,
    ]);

    useEffect(() => {
      if (credentialId) {
        setSelectedCredential(credentials.find(c => c.credentialId === credentialId) ?? null);
        if (credentialId === NEW_CREDENTIAL_VALUE) {
          dispatch(CredentialsActions.credentialDetails.requestData({ packageType: selectedPackage.packageType }));
        }
      }
    }, [credentialId, credentials, dispatch, selectedPackage]);

    useEffect(() => {
      if (selectedPackage) {
        getCredentials(selectedPackage);
      }
    }, [getCredentials, selectedPackage]);

    useEffect(() => {
      if (!credentialsLoading && credentials?.length === 1) {
        changeCredentialId({ value: credentials[0].credentialId });
      }
    }, [changeCredentialId, credentials, credentialsLoading]);

    const changePackageSource = useCallback(
      ({ value: packageSource }) => {
        setPackageSource(packageSource);
        changePackage({
          value:
            packageSource === ConnectionSourcePackageTypeEnum.Manual ||
            packageSource === ConnectionSourcePackageTypeEnum.OCR
              ? packageSource
              : null,
        });
      },
      [changePackage],
    );

    const handleOAuthConnectClick = useCallback(async () => {
      const formik = formRef.current;
      await createCredential(formik, credential => {
        window.open(
          `${process.env.REACT_APP_AUTH_URL}/connect/${credential.packageType}/${credential.credentialId}`,
          '_blank',
          'noopener,noreferrer',
        );
      });
    }, [createCredential, formRef]);

    const packageSourceField = useMemo(
      () => ({
        name: 'packageSource',
        type: 'select',
        required: true,
        header: 'products.packageSource',
        items: getPackageSources(selectedProduct),
        disabled: existingConnection,
        tooltipText: 'tooltip.inputData',
      }),
      [existingConnection, selectedProduct],
    );

    const packageIdField = useMemo(
      () => ({
        name: 'packageId',
        type: 'autocomplete',
        required: packageRequired,
        disabled: !packageRequired || existingConnection,
        header: 'products.selectPackage',
        placeholder: intl.formatMessage({ id: 'products.selectPackage' }),
        loading: (loading || packageTypesLoading) && packageRequired,
        items: packageRequired
          ? availableSourcePackages.map(p => ({
              element: startCase(p.packageType.replace('-', ' ')),
              value: p['@id'],
            }))
          : [],
      }),
      [packageRequired, existingConnection, intl, loading, packageTypesLoading, availableSourcePackages],
    );

    const credentialIdField = useMemo(() => {
      const disabled = !packageId;

      const items = disabled
        ? []
        : credentials.map(credential => ({
            element: credential.name,
            value: credential.credentialId,
          }));

      if (hasAccessToEditCredentials) {
        items.push({
          element: intl.formatMessage({ id: 'onboarding.credentials.createNewCredential' }),
          value: NEW_CREDENTIAL_VALUE,
        });
      }

      return {
        name: 'credentialId',
        type: 'autocomplete',
        required: packageRequired,
        disabled: disabled,
        header: selectedPackage ? selectedPackageName : 'onboarding.credentials.credential',
        placeholder: intl.formatMessage({ id: 'onboarding.credentials.credential' }),
        defaultValue: (credentials || []).find(c => c.credentialId === credentialId),
        loading: (loading || credentialsLoading || creatingCredential) && !disabled,
        items: items.reverse(),
      };
    }, [
      loading,
      creatingCredential,
      credentialId,
      credentials,
      credentialsLoading,
      intl,
      packageId,
      packageRequired,
      selectedPackage,
      selectedPackageName,
      hasAccessToEditCredentials,
    ]);

    const settingsFields = useMemo(() => {
      if (!showNewCredentialsFields || isEmpty(credentialDetails) || isNil(selectedPackage)) {
        return [];
      }
      return mapSettingsField(
        intl,
        credentialDetails,
        'onboarding.credentials.new.',
        credentialDetailsLoading,
        initialPackage?.settings,
        stepState.form,
      );
    }, [
      showNewCredentialsFields,
      credentialDetails,
      selectedPackage,
      intl,
      credentialDetailsLoading,
      initialPackage?.settings,
      stepState.form,
    ]);

    const initialValues = useMemo(() => {
      let packages = {};
      switch (packageSource) {
        case ConnectionSourcePackageTypeEnum.Manual:
          packages = { 'manual-input': {} };
          break;
        case ConnectionSourcePackageTypeEnum.OCR:
          packages = { ocr: {} };
          break;
        default:
          packages = {};
          break;
      }

      let values = {
        packageSource: packageSource,
        packageId: packageId,
        credentialId: credentialId,
        packages: packages,
      };

      settingsFields.forEach(field => {
        values = setIn(values, field.name, field.value ?? field.defaultValue ?? null);
      });

      return values;
    }, [packageSource, packageId, credentialId, settingsFields]);

    return (
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} innerRef={formRef}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputFieldFactory
                field={packageSourceField}
                onChange={changePackageSource}
                disabled={creatingCredential}
              />
            </Grid>
            {!isFileUpload && (
              <Fade in={packageRequired}>
                <Grid item xs={4}>
                  <InputFieldFactory field={packageIdField} onChange={changePackage} disabled={creatingCredential} />
                </Grid>
              </Fade>
            )}
            <Fade in={packageRequired}>
              <Grid item xs={4}>
                <InputFieldFactory
                  field={credentialIdField}
                  onChange={changeCredentialId}
                  disabled={creatingCredential}
                />
              </Grid>
            </Fade>
          </Grid>
          <Box p={1} />
          <CollapsableSection
            header={
              intl.formatMessage({ id: 'onboarding.credentials.createNewCredential' }) + ' - ' + selectedPackageName
            }
            loading={loading || credentialDetailsLoading || creatingCredential}
            expanded={!credentialDetailsLoading && !isEmpty(settingsFields)}
            hidden={!showNewCredentialsFields}
            controllable
          >
            {!credentialDetailsLoading && showNewCredentialsFields && (
              <>
                <Box p={1} />
                <Grid container spacing={2}>
                  <>
                    {settingsFields.map((field, index) => (
                      <Grid item key={index} xs={4}>
                        <InputFieldFactory field={field} disabled={creatingCredential} />
                      </Grid>
                    ))}
                    {credentialDetails.credentialType === CREDENTIAL_TYPE_OAUTH && (
                      <Grid item xs={4}>
                        <ActionButton
                          header={intl.formatMessage(
                            { id: 'connection.onboard.connectTo' },
                            { package: selectedPackageName },
                          )}
                          messageId={'credentialRequests.connect'}
                          variant="outlined"
                          onClick={handleOAuthConnectClick}
                          disabled={creatingCredential}
                        />
                      </Grid>
                    )}
                  </>
                </Grid>
              </>
            )}
          </CollapsableSection>
        </>
      </Formik>
    );
  },
);

SourcePackageCredentialsStep.displayName = 'SourcePackageCredentialsStep';

export default SourcePackageCredentialsStep;
