import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import type { User } from '../../../../types';

let getUsersToken: CancelTokenSource;

export type GetUsersParams = {
  page?: number;
  perPage?: number;
  searchQuery?: string;
  internal?: boolean;
};

const getUsers = async (params?: GetUsersParams) => {
  const { page, perPage, searchQuery } = params || {};

  try {
    if (typeof getUsersToken !== typeof undefined) {
      getUsersToken.cancel('Operation canceled due to new request.');
    }

    getUsersToken = axios.CancelToken.source();

    return axios.get('users', {
      cancelToken: getUsersToken.token,
      params: {
        page,
        perPage,
        user: searchQuery,
        internal: params?.internal,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export type GetOwnerUsersParams = {
  ownerId: User['userId'];
  page?: number;
  perPage?: number;
  searchQuery?: string;
  internal?: boolean;
};

const getOwnerUsers = async ({ ownerId, page, perPage, searchQuery, internal }: GetOwnerUsersParams) => {
  try {
    if (typeof getUsersToken !== typeof undefined) {
      getUsersToken.cancel('Operation canceled due to new request.');
    }

    getUsersToken = axios.CancelToken.source();

    return axios.get(`/owners/${ownerId}/users`, {
      cancelToken: getUsersToken.token,
      params: {
        page,
        perPage,
        user: searchQuery,
        internal,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

const getEventFlowUsers = async ({ ownerId, page, perPage, searchQuery, internal }: GetOwnerUsersParams) => {
  try {
    if (typeof getUsersToken !== typeof undefined) {
      getUsersToken.cancel('Operation canceled due to new request.');
    }

    getUsersToken = axios.CancelToken.source();

    return axios.get(`/owners/${ownerId}/event-flow-users`, {
      cancelToken: getUsersToken.token,
      params: {
        page,
        perPage,
        user: searchQuery,
        internal,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

const getUser = async (userId: User['userId']) => {
  return axios.get(`/users/${userId}`);
};

export type CreateUserArgs = Pick<User, 'userId' | 'ownerId'> & {
  roleId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  emailAddress: string;
  language: User['language'];
  internal: boolean;
};

const createUser = async (values: CreateUserArgs) => {
  return axios.post('/users', { ...values }).then(response => response.data);
};

export type EditUserArgs = CreateUserArgs &
  Pick<User, 'accesses'> &
  Pick<User, 'eventFlows'> &
  Pick<User, 'autoAssignEmployers'>;

const editUser = async (values: EditUserArgs) => {
  return axios.put(`/users/${values.userId}`, { ...values });
};

const deleteUser = async (userId: User['userId']) => {
  return axios.delete(`/users/${userId}`);
};

const resetUser2FaToken = async (userId: User['userId']) => {
  return axios.post(`/auth/reset-2fa-token/${userId}`, { userId });
};

const requestAPIKey = async (userId: User['userId']) => {
  return axios.get(`/users/${userId}/apikey`);
};

export type UpdateUserOwnerIdArgs = Pick<User, 'userId' | 'ownerId'> & {
  ownerId: string;
  ownerName: string;
};

const editUserOwnerId = async (values: UpdateUserOwnerIdArgs) => {
  return axios.put(`/users/${values.userId}/owner`, { ...values });
};

export type CreateUserEnvironmentsArgs = Pick<User, 'userId'> & {
  userId: string;
  ownerIds: Array<string>;
};

const createUserEnvironments = async (values: CreateUserEnvironmentsArgs) => {
  return axios.post('/users/environments', { ...values });
};

export const userService = {
  getUsers,
  createUser,
  getOwnerUsers,
  getEventFlowUsers,
  getUser,
  editUser,
  deleteUser,
  resetUser2FaToken,
  requestAPIKey,
  editUserOwnerId,
  createUserEnvironments,
};
