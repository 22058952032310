import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { backgroundAxios } from 'app/utils';

let getEmployersToken: CancelTokenSource;

export type GetEmployers = {
  page?: number;
  perPage?: number;
  searchQuery?: string;
  pagination?: boolean;
};

const getOwnerEmployersTotalCount = async (userId: string) => {
  return axios.get(`/users/${userId}/employers-total-count`);
};

const getEmployer = async (employerId: string) => {
  return axios.get(`/employers/${employerId}`);
};

const createEmployer = async (values: any) => {
  return axios.post(`/owners/${values.ownerId}/employers`, { ...values });
};

const bulkCreateEmployers = async (ownerId: string, employers: any) => {
  return axios.post(`/owners/${ownerId}/employers/create-multiple`, { employers });
};

const editEmployer = async (values: any) => {
  return axios.put(`/employers/${values.employerId}`, { ...values });
};

const deleteEmployer = async (employerId: string) => {
  return axios.delete(`/employers/${employerId}`);
};

const validateField = async (field: string, value: any, employerId: string) => {
  return axios.post(`/employers/validate`, {
    field,
    value,
    employerId,
  });
};

type GetEmployeesParams = {
  employerId: string;
  values?: {
    productId?: string;
    connectionId?: string;
    employeeTypeId?: string;
    status?: string;
    employeeSearch?: string;
  };
};

const getEmployees = async ({ employerId, values = {} }: GetEmployeesParams) => {
  return backgroundAxios.get(`/employers/${employerId}/employees`, { params: { ...values } });
};

export type GetOwnerEmployersParams = {
  userId: string;
  page?: number;
  perPage?: number;
  searchQuery?: string;
  ownerId?: string;
};

const getOwnerEmployers = async ({ userId, page, perPage, searchQuery, ownerId }: GetOwnerEmployersParams) => {
  try {
    if (typeof getEmployersToken !== typeof undefined) {
      getEmployersToken.cancel('Operation canceled due to new request.');
    }

    getEmployersToken = axios.CancelToken.source();

    return axios.get(`/users/${userId}/employers`, {
      cancelToken: getEmployersToken.token,
      params: {
        page,
        perPage,
        employer: searchQuery,
        ownerId,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export type GetUsersEmployersParam = {
  userId: string;
  params: {
    page?: number;
    perPage?: number;
    searchQuery?: string;
    sortBy?: Record<string, unknown>;
  };
};

const getUserEmployers = async ({ userId, ...params }: GetUsersEmployersParam) => {
  try {
    if (typeof getEmployersToken !== typeof undefined) {
      getEmployersToken.cancel('Operation canceled due to new request.');
    }

    getEmployersToken = axios.CancelToken.source();

    return axios.get(`/users/${userId}/employers`, {
      cancelToken: getEmployersToken.token,
      ...params,
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export type CreateConnectionParams = {
  employerId: string;
  values: any;
};

const createConnection = async ({ employerId, values }: CreateConnectionParams) => {
  return axios.post(`/employers/${employerId}/onboard`, { ...values });
};

export type UpdateConnectionParams = {
  connectionId: string;
  values: any;
};

const updateConnection = async ({ connectionId, values }: UpdateConnectionParams) => {
  return axios.put(`/connections/${connectionId}`, { ...values });
};

export type DeleteConnectionParams = {
  connectionId: string;
};

const deleteConnection = async ({ connectionId }: DeleteConnectionParams) => {
  return axios.delete(`/connections/${connectionId}`);
};

export type GetEmployerNotesParams = {
  ownerId: string;
  employerId: string;
};

const getEmployerNotes = async ({ ownerId, employerId }: GetEmployerNotesParams) => {
  return backgroundAxios.get(`/owners/${ownerId}/employers/${employerId}/notes`);
};

export type CreateEmployerNotesParams = {
  ownerId: string;
  employerId: string;
  noteText: string;
};

const createEmployerNotes = async ({ ownerId, employerId, noteText }: CreateEmployerNotesParams) => {
  return axios.post(`/owners/${ownerId}/employers/${employerId}/notes`, {
    employerId,
    text: noteText,
  });
};

export type UpdateEmployerNotesParams = {
  ownerId: string;
  employerId: string;
  noteId: string;
  noteText: string;
};

const updateEmployerNotes = async ({ ownerId, employerId, noteId, noteText }: UpdateEmployerNotesParams) => {
  return axios.put(`/owners/${ownerId}/employers/${employerId}/notes/${noteId}`, {
    employerId,
    noteId,
    text: noteText,
  });
};

type GetEmployerFilesParams = {
  employerId: string;
  values: any;
};

const getEmployerFiles = async ({ employerId, values = {} }: GetEmployerFilesParams) => {
  return backgroundAxios.get(`/employers/${employerId}/files`, {
    params: { ...values },
  });
};

export type DeleteEmployerFileParams = {
  fileId: string;
  employerId?: string;
};

const deleteEmployerFile = async ({ fileId, employerId }: DeleteEmployerFileParams) => {
  return axios.delete(`/employers/${employerId}/files/${fileId}`);
};

export type CreateEmployerFileParams = {
  employerId?: string | null;
  employeeId?: string | null;
  title: string;
  base64File: string;
};

const createEmployerFile = async ({ employerId, title, base64File }: CreateEmployerFileParams) => {
  return axios.post(`/employers/${employerId}/files`, {
    title,
    base64File,
  });
};

export type DownloadEmployerFileParams = {
  employerId?: string | null;
  fileId?: string | null;
  encodedFilename: string;
};

export const downloadEmployerFile = async ({ employerId, fileId, encodedFilename }: DownloadEmployerFileParams) => {
  return axios.get(`/employers/${employerId}/download/${fileId}/${encodedFilename}`);
};

export const employerService = {
  getEmployees,
  deleteEmployer,
  editEmployer,
  getEmployer,
  createEmployer,
  bulkCreateEmployers,
  getOwnerEmployers,
  getUserEmployers,
  getOwnerEmployersTotalCount,
  createConnection,
  updateConnection,
  deleteConnection,
  getEmployerNotes,
  createEmployerNotes,
  updateEmployerNotes,
  getEmployerFiles,
  deleteEmployerFile,
  createEmployerFile,
  downloadEmployerFile,
  validateField,
};
