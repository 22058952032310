import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@mui/material';

import type { ReportEmployer, ReportSubOwner } from '../../../../../../../../types';

import { TranslationKeys } from '../../../../../../../../translations';

import {
  ActionLink,
  ListCollapsibleRow,
  ListRow,
  ListRowNoData,
  ListRowSkeleton,
} from '../../../../../../../../components';

import type { UserAccessesListOnChangeSelected, UserAccessesListSelectedData } from '../../UserAccessesList.types';

import { UserAccessesListHeader } from '../UserAccessesListHeader';
import { UserAccessesListContainer } from '../UserAccessesListContainer';
import { MuiCheckboxStyled } from '../../../../../../../../components/Checkbox/Checkbox.styles';

type UserAccessesListSubOwnersProps = {
  isLoading?: boolean;
  isLoggedInUser?: boolean;
  onChangeSelected: UserAccessesListOnChangeSelected;
  selectedData: UserAccessesListSelectedData | undefined;
  subOwners: Array<ReportSubOwner> | undefined;
  selectedEventFlows: Array<string> | undefined;
  openConfirmationDialog: (value: boolean) => void;
};

export const UserAccessesListSubOwners = ({
  subOwners,
  selectedData,
  onChangeSelected,
  selectedEventFlows = [],
  openConfirmationDialog,
  isLoggedInUser = false,
  isLoading = false,
}: UserAccessesListSubOwnersProps) => {
  const [searchQuery, setSearchQuery] = React.useState('');

  const listData = React.useMemo(() => {
    if (!searchQuery) {
      return subOwners;
    }

    const searchWords = searchQuery.toLowerCase().split(' ');
    const matchSearchQuery = (value: string) => searchWords.every(word => value.toLowerCase().includes(word));

    return subOwners?.reduce<ReportSubOwner[]>((acc, subOwner) => {
      const matchSubOwner = matchSearchQuery(subOwner.name);
      const matchEmployers = subOwner.employers.some(employer => matchSearchQuery(employer.name));
      const isAMatch = matchSubOwner || matchEmployers;

      if (isAMatch) {
        acc.push({
          ...subOwner,
          employers: matchEmployers
            ? subOwner.employers.filter(employer => matchSearchQuery(employer.name))
            : subOwner.employers,
        });
      }

      return acc;
    }, []);
  }, [searchQuery, subOwners]);

  const selected = Object.keys(selectedData?.subOwners || {});
  const disabledIfLoadingOrIsLoggedInUser = isLoggedInUser || isLoading;
  const hasData = listData && listData?.length > 0;
  const showData = !isLoading && hasData;
  const showNoData = !isLoading && !showData;

  const onChangeSearchInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  }, []);

  const onSelectAll = React.useCallback(() => {
    const newSelectedData = JSON.parse(JSON.stringify(selectedData));

    if (subOwners && !selectedData?.areAllSubOwnersSelected) {
      newSelectedData.areAllSubOwnersSelected = true;

      newSelectedData.subOwners = subOwners.reduce<Record<string, string>>((acc, subOwner) => {
        acc[subOwner.subOwnerId] = '*';
        return acc;
      }, {});
    } else {
      newSelectedData.areAllSubOwnersSelected = false;
      newSelectedData.subOwners = {};
    }

    onChangeSelected(newSelectedData);
  }, [onChangeSelected, selectedData, subOwners]);

  const onSelectHandler = React.useCallback(
    (subOwner: ReportSubOwner) => () => {
      const newSelectedData = JSON.parse(JSON.stringify(selectedData));
      newSelectedData.areAllSubOwnersSelected = false;

      const isSelected = Object.keys(newSelectedData?.subOwners || {}).some(
        subOwnerId => subOwnerId === subOwner.subOwnerId,
      );

      if (isSelected) {
        delete newSelectedData.subOwners[subOwner.subOwnerId];
        onChangeSelected({
          ...newSelectedData,
          subOwners: {
            ...(newSelectedData?.subOwners || {}),
          },
        });
      }

      if (!isSelected) {
        onChangeSelected({
          ...newSelectedData,
          subOwners: {
            ...(newSelectedData?.subOwners || {}),
            [subOwner.subOwnerId]: '*',
          },
        });
      }
    },
    [onChangeSelected, selectedData],
  );

  const onSelectEmployerHandler = React.useCallback(
    (subOwner: ReportSubOwner, employer: ReportEmployer) => () => {
      const newSelectedData = JSON.parse(JSON.stringify(selectedData));
      newSelectedData.areAllSubOwnersSelected = false;

      const selectedSubOwner = Object.keys(newSelectedData?.subOwners || {}).find(
        subOwnerId => subOwnerId === subOwner.subOwnerId,
      );

      const isSubOwnerSelected = !!selectedSubOwner;

      if (!isSubOwnerSelected) {
        newSelectedData.subOwners = {
          ...(newSelectedData.subOwners || {}),
          [subOwner.subOwnerId]: '*',
        };
      }

      const isEmployerSelected = Object.keys(newSelectedData.subOwners[subOwner.subOwnerId] || {}).some(
        employerId => employerId === employer.employerId,
      );

      if (!isEmployerSelected) {
        if (newSelectedData.subOwners[subOwner.subOwnerId] !== '*') {
          newSelectedData.subOwners[subOwner.subOwnerId] = {
            ...(newSelectedData.subOwners[subOwner.subOwnerId] || {}),
            [employer.employerId]: '*',
          };
        } else {
          newSelectedData.subOwners[subOwner.subOwnerId] = {
            [employer.employerId]: '*',
          };
        }
      }

      if (isEmployerSelected) {
        delete newSelectedData.subOwners[subOwner.subOwnerId][employer.employerId];

        if (Object.keys(newSelectedData.subOwners[subOwner.subOwnerId]).length === 0) {
          newSelectedData.subOwners[subOwner.subOwnerId] = '*';
        }
      }

      onChangeSelected(newSelectedData);
    },
    [onChangeSelected, selectedData],
  );

  const onSelectEventFlow = React.useCallback(
    (employer: ReportEmployer) => () => {
      const selectedEventFlows = selectedData?.eventFlows || [];
      const autoAssignEmployers = selectedData?.autoAssignEmployers || false;
      const employerSelected = selectedEventFlows.includes(employer.employerId);

      // Use a functional approach to avoid direct mutation
      const newSelectedEventFlows = employerSelected
        ? selectedEventFlows.filter(id => id !== employer.employerId)
        : [...selectedEventFlows, employer.employerId];
      if (selectedData) {
        openConfirmationDialog(employer.eventFlowDefaultUser !== null);
        onChangeSelected({
          ...selectedData,
          eventFlows: newSelectedEventFlows,
        });
      } else {
        onChangeSelected({
          areAllEmployersSelected: autoAssignEmployers,
          areAllSubOwnersSelected: false,
          eventFlows: newSelectedEventFlows,
          autoAssignEmployers: autoAssignEmployers,
        });
      }
    },
    [onChangeSelected, openConfirmationDialog, selectedData],
  );

  const renderSubOwnerEmployers = React.useCallback(
    (subOwner: ReportSubOwner) => {
      const selectedSubOwner = selectedData?.subOwners?.[subOwner.subOwnerId as keyof typeof selectedData.subOwners];

      return subOwner.employers.map(employer => {
        const isSelected = Object.keys(selectedSubOwner || {}).some(employerId => employerId === employer.employerId);
        const isEventFlowSelected = selectedEventFlows.some(eventFlowId => eventFlowId === employer.employerId);

        return (
          <ListRow
            key={`${subOwner.subOwnerId}_${employer.employerId}`}
            selected={isSelected}
            onSelect={onSelectEmployerHandler(subOwner, employer)}
            disabled={disabledIfLoadingOrIsLoggedInUser}
            innerRow
          >
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={2}>
              <Typography>
                <ActionLink to={`/employers/${employer.employerId}`}>{employer.name}</ActionLink>
              </Typography>
              <Typography variant={'caption'} textTransform={'initial'}>
                <FormattedMessage
                  id={TranslationKeys.users_accessesList_subEmployers}
                  values={{ count: employer.subEmployers?.length }}
                />
              </Typography>
              <Typography sx={{ marginLeft: 'auto', padding: '0 10px 0 0' }}>
                <MuiCheckboxStyled
                  color={'secondary'}
                  onClick={onSelectEventFlow(employer)}
                  checked={isEventFlowSelected && isSelected}
                  disabled={!isSelected || disabledIfLoadingOrIsLoggedInUser}
                />
                <Typography variant={'caption'} textTransform={'initial'}>
                  <FormattedMessage id={TranslationKeys.users_accessesList_defaultEventFlowUser} />
                </Typography>
              </Typography>
            </Box>
          </ListRow>
        );
      });
    },
    [selectedData, selectedEventFlows, onSelectEmployerHandler, disabledIfLoadingOrIsLoggedInUser, onSelectEventFlow],
  );

  return (
    <Box sx={theme => ({ marginTop: theme.spacing(2) })}>
      <UserAccessesListHeader
        headerTextId={TranslationKeys.users_accessesList_subOwners}
        onChange={onChangeSearchInput}
        isLoading={isLoading}
        disabled={disabledIfLoadingOrIsLoggedInUser}
        onSelectAll={onSelectAll}
        areAllSelected={selectedData?.areAllSubOwnersSelected}
        noData={listData?.length === 0}
        count={selected.length}
      />

      <UserAccessesListContainer>
        {isLoading && <ListRowSkeleton />}

        {showData &&
          listData?.map(subOwner => {
            const hasEmployers = subOwner.employers?.length > 0;
            const isSelected = selected.some(subOwnerId => subOwnerId === subOwner.subOwnerId);

            const selectedSubOwner =
              selectedData?.subOwners?.[subOwner.subOwnerId as keyof typeof selectedData.subOwners];

            const hasSelectedChildren = isSelected && !!selectedSubOwner && selectedSubOwner !== '*';

            return (
              <ListCollapsibleRow
                key={subOwner.subOwnerId}
                selected={isSelected}
                onSelect={onSelectHandler(subOwner)}
                disabled={disabledIfLoadingOrIsLoggedInUser}
                childrenExtraLeftPadding={!hasEmployers}
                rowHeader={
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                    <Typography>
                      <ActionLink to={`/sub-owners/${subOwner.subOwnerId}`}>{subOwner.name}</ActionLink>
                    </Typography>
                    {hasSelectedChildren && (
                      <Typography variant={'caption'} textTransform={'initial'}>
                        <FormattedMessage
                          id={TranslationKeys.users_accessesList_selectedCountEmployers}
                          values={{ count: Object.keys(selectedSubOwner).length }}
                        />
                      </Typography>
                    )}
                  </Box>
                }
              >
                {hasEmployers ? (
                  renderSubOwnerEmployers(subOwner)
                ) : (
                  <ListRowNoData searchQuery={searchQuery} innerRow />
                )}
              </ListCollapsibleRow>
            );
          })}

        {showNoData && <ListRowNoData searchQuery={searchQuery} />}
      </UserAccessesListContainer>
    </Box>
  );
};
